/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var templateUrl = object_name.templateUrl;

        AOS.init();


        $(window).on("scroll", function() {
          if($(window).scrollTop() > 50) {
            $(".main-navbar").addClass("scrolled");
          } else {
            //remove the background property so it comes transparent again (defined in your css)
            $(".main-navbar").removeClass("scrolled");
          }
        });

        //// MENU

        var marker = $('#marker'),
          current = $('.current_page_item, .current_page_parent, .current-menu-item');
        // Initialize the marker position and the active class

        if ($('#menu-primary li').hasClass('current_page_item', 'current_page_parent', 'current-menu-item')) {

          current.addClass('active');
          marker.css({
            // Place the marker in the middle of the border
            bottom: -(marker.height() / 2),
            left: current.position().left,
            width: current.outerWidth(),
            display: "block"
          });

          $('.navbar-nav-primary li').mouseover(function () {
            var self = $(this),
              offsetLeft = self.position().left,
              width = self.outerWidth() || current.outerWidth(),

              left = offsetLeft === 0 ? 0 : offsetLeft || current.position().left;

            $('.active').removeClass('active');
            self.addClass('active');
            marker.stop().animate({
              left: left,
              width: width,
            }, 300);
          });

          $('.navbar-nav-primary li').mouseleave(function () {

            $('.active').removeClass('active');
            current.addClass('active');

            marker.stop().animate({
              left: current.position().left,
              width: current.outerWidth()
            }, 200);
          });

        }


        /// SVG ANIMATE

        if (document.getElementById('h-frame')) {
          var h_frame = new Vivus('h-frame', {
            file: templateUrl + '/dist/images/h-frames/h-frame.svg',
            type: 'sync',
            duration: 120,
            animTimingFunction: Vivus.EASE_OUT,
          });
        }

        if (document.getElementById('h-frame-1')) {
          var h_frame_1 = new Vivus('h-frame-1', {
            file: templateUrl + '/dist/images/h-frames/h-frame-3a.svg',
            type: 'sync',
            duration: 100,
            animTimingFunction: Vivus.EASE_OUT,
          });
        }

        if (document.getElementById('h-frame-sm')) {
          var h_frame_sm = new Vivus('h-frame-sm', {
            file: templateUrl + '/dist/images/h-frames/h-frame-sm.svg',
            type: 'sync',
            duration: 100,
            animTimingFunction: Vivus.EASE_OUT,
          });
        }

        if (document.getElementById('h-frame-2')) {
          var h_frame_2 = new Vivus('h-frame-2', {
            file: templateUrl + '/dist/images/h-frames/h-frame.svg',
            type: 'sync',
            duration: 100,
            animTimingFunction: Vivus.EASE_OUT
          });
        }



        ////// HOMEPAGE




        var owl = $('.owl-carousel');

        function startProgressBar(event) {
          // apply keyframe animation
          $(".slide-progress").css({
            width: "100%",
            transition: "width 5000ms"
          });

        }

        function resetProgressBar() {
          $(".slide-progress").css({
            width: 0,
            transition: "width 0s"
          });
        }

        //Init the carousel


        function initHomeSlider() {
          owl.on('initialized.owl.carousel changed.owl.carousel', function(e) {
            if (!e.namespace)  {
              return;
            }
            var carousel = e.relatedTarget;
            $('.slider-counter').html('<span class="current">0'+ carousel.relative(carousel.current() + 1 ) +'</span>' + '<span class="separator">/</span>0' + carousel.items().length);
          }).owlCarousel({
            animateOut: 'fadeOut',
            items: 1,
            loop: true,
            autoplay: false,
            onInitialized: startProgressBar,
            onTranslate: resetProgressBar,
            onTranslated: startProgressBar,
          });


          $('.owl-dot').click(function () {
            owl.trigger('to.owl.carousel', [$(this).index(), 300]);
          });

          $('.owl-play').on('click',function(){
            owl.trigger('play.owl.autoplay',[4000]);

            $(this).parent().removeClass('is-paused');
            $(this).parent().addClass('is-played');
          });

          $('.owl-stop').on('click',function(){
            owl.trigger('stop.owl.autoplay');

            $(this).parent().removeClass('is-played');
            $(this).parent().addClass('is-paused');
          });


          $('.slider-autoplay').click(function () {

          });
        }

        initHomeSlider();



        ///// SCROLLING HASHED

        // Select all links with hashes
        $('a[href*="#"]')
        // Remove links that don't actually link to anything
          .not('[href="#"]')
          .not('[href="#0"]')
          .click(function(event) {
            // On-page links
            if (
              location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname
            ) {
              // Figure out element to scroll to
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
              // Does a scroll target exist?
              if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                  scrollTop: target.offset().top
                }, 600, function() {
                  // Callback after animation
                  // Must change focus!
                  var $target = $(target);
                  $target.focus();
                  if ($target.is(":focus")) { // Checking if the target was focused
                    return false;
                  } else {
                    $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                    $target.focus(); // Set focus again
                  }
                });
              }
            }
          });

        //// ANIMATION

        $(window).scroll(function() {
          var scroll = $(window).scrollTop();
          var hasBeenTrigged = false;
          var opacity=0;

          if (scroll < $(window).height() ) {
            var offset = scroll / 50;
            $(".clouds-bg").css({
              transform: 'translate3d(0%,' + offset + '%, 0) scale(1)'
            });
          }

        });




      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page





      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'blog': {
      init: function() {

        $('.post-listings').masonry({
          columnWidth: '.post-grid-sizer',
          itemSelector: '.post-item',
          percentPosition: true
        });

        AOS.refresh();


      }
    }
  };


  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
